<template>
  <div>
    <b-form-group
      id="input-group-title"
      label="Judul Surat:"
      label-for="input-title"
    >
      <b-form-input
        id="input-title"
        v-model="form.title"
        placeholder="Judul Surat"
        :readonly="titleReadonly ? true : false"
      ></b-form-input>
      <small class="text-danger">{{ error.title }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-number"
      label="No Surat:"
      label-for="input-number"
    >
      <b-form-input
        id="input-number"
        v-model="form.number"
        placeholder="No Surat"
      ></b-form-input>
      <small class="text-danger">{{ error.number }}</small>
    </b-form-group>

    <b-form-group id="input-group-opening_sentences">
      <label for="input-opening_sentences">Kalimat Pembuka: </label>
      <b-form-textarea
        id="input-opening_sentences"
        v-model="form.opening_sentences"
        placeholder="Kalimat Pembuka"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.opening_sentences }}</small>
    </b-form-group>

    <slot name="after-opening-sentence"></slot>

    <!-- Citizen -->
    <!-- <b-form-group
      id="input-group-citizen"
      label="Penduduk:"
      label-for="input-citizen"
    >
      <treeselect
        v-model="form.citizen_id"
        :multiple="false"
        placeholder="Pilih Penduduk"
        :options="citizen"
      />
      <small class="text-danger">{{ error.citizen_id }}</small>
    </b-form-group> -->
    <div class="row mb-2">
      <div class="col">
        <button
          class="btn btn-info btn-block"
          type="button"
          @click="$bvModal.show('modal-citizen')"
        >
          {{ citizenTitleButton }}
        </button>
        <small class="text-danger">{{ error.citizen_id }}</small>
      </div>
    </div>

    <!-- Name input -->
    <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
      <b-form-input
        id="input-name"
        v-model="form.citizen_name"
        placeholder="Nama"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.name }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-id_card_number"
      label="NIK:"
      label-for="input-id_card_number"
    >
      <b-form-input
        id="input-id_card_number"
        v-model="form.citizen_id_card_number"
        placeholder="NIK"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.id_card_number }}</small>
    </b-form-group>

    <b-form-group
      v-if="genderShow"
      id="input-group-gender"
      label="Jenis Kelamin:"
      label-for="input-gender"
    >
      <b-form-input
        id="input-gender"
        v-model="form.citizen_gender_display"
        placeholder="Jenis Kelamin"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.gender }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-birt_place_date"
      label="Tempat, tanggal lahir:"
      label-for="input-birt_place_date"
    >
      <b-form-input
        id="input-birt_place_date"
        v-model="form.birt_place_date"
        placeholder="Tempat, tanggal lahir"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.birt_place_date }}</small>
    </b-form-group>
    <span class="d-none">{{ form.birt_place_date }}</span>

    <b-form-group
      v-if="citizenshipShow"
      id="input-group-citizenship"
      label="Warganegara:"
      label-for="input-citizenship"
    >
      <b-form-input
        id="input-citizenship"
        v-model="form.citizen_citizenship"
        placeholder="Warganegara"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.citizenship }}</small>
    </b-form-group>

    <b-form-group
      v-if="religionShow"
      id="input-group-religion"
      label="Agama:"
      label-for="input-religion"
    >
      <b-form-input
        id="input-religion"
        v-model="form.citizen_religion"
        placeholder="Agama"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.religion }}</small>
    </b-form-group>

    <b-form-group
      v-if="professionShow"
      id="input-group-profession"
      label="Pekerjaan:"
      label-for="input-profession"
    >
      <b-form-input
        id="input-profession"
        v-model="form.citizen_profession"
        placeholder="Pekerjaan"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.profession }}</small>
    </b-form-group>

    <b-form-group
      v-if="isMarriedShow"
      id="input-group-is_married"
      label="Status Pernikahan:"
      label-for="input-is_married"
    >
      <b-form-input
        id="input-is_married"
        v-model="form.citizen_is_married_display"
        placeholder="Status Pernikahan"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.is_married }}</small>
    </b-form-group>

    <!-- <b-form-group
      id="input-group-hamlet_id"
      label="No. WA Ketua RW:"
      label-for="input-hamlet_id"
    >
      <treeselect
        v-model="form.hamlet_id"
        :multiple="false"
        placeholder="Pilih No. WA Ketua RW"
        :options="hamlets"
      />
      <small class="text-danger">{{ error.hamlet_id }}</small>
    </b-form-group> -->

    <!-- Description input -->
    <b-form-group id="input-group-address" v-if="addressShow">
      <label for="input-address">Alamat Lengkap: </label>
      <b-form-textarea
        id="input-address"
        v-model="form.citizen_address"
        placeholder="Alamat"
        rows="4"
        max-rows="8"
        readonly
      ></b-form-textarea>
      <small class="text-danger">{{ error.description }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-phone"
      label="No. WA:"
      label-for="input-phone"
    >
      <b-form-input
        id="input-phone"
        v-model="form.phone"
        placeholder="No. WA"
      ></b-form-input>
      <small class="text-danger">{{ error.phone }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-purpose"
      label="Keperluan:"
      label-for="input-purpose"
    >
      <b-form-input
        id="input-purpose"
        v-model="form.purpose"
        placeholder="Keperluan"
      ></b-form-input>
      <small class="text-danger">{{ error.purpose }}</small>
    </b-form-group>

    <b-form-group id="input-group-main_sentences" v-if="mainSentenceShow">
      <label for="input-main_sentences">Kalimat Utama: </label>
      <b-form-textarea
        id="input-main_sentences"
        v-model="form.main_sentences"
        placeholder="Kalimat Utama"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.main_sentences }}</small>
    </b-form-group>

    <slot name="main"></slot>

    <b-form-group id="input-group-closing_sentences">
      <label for="input-closing_sentences">Kalimat Penutup: </label>
      <b-form-textarea
        id="input-closing_sentences"
        v-model="form.closing_sentences"
        placeholder="Kalimat Penutup"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.closing_sentences }}</small>
    </b-form-group>

    <ModalCitizen @chooseCitizen="chooseCitizen" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import ModalCitizen from "@/view/components/general/ModalCitizen.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    error: Object,
    purpose: {
      type: String,
      default: "add",
    },
    citizenTitleButton: {
      type: String,
      default: "Pilih Penduduk",
    },
    mainSentenceShow: {
      type: Boolean,
      default: true,
    },
    genderShow: {
      type: Boolean,
      default: true,
    },
    citizenshipShow: {
      type: Boolean,
      default: true,
    },
    professionShow: {
      type: Boolean,
      default: true,
    },
    religionShow: {
      type: Boolean,
      default: true,
    },
    addressShow: {
      type: Boolean,
      default: true,
    },
    isMarriedShow: {
      type: Boolean,
      default: true,
    },
    letterType: {
      type: Number,
      default: 0,
    },
    titleReadonly: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    ModalCitizen,
  },

  data() {
    return {
      // Error
      // error: {
      //   citizen_id: "",
      //   name: "",
      //   id_card_number: "",
      //   gender: "",
      //   birt_place: "",
      //   birt_date: "",
      //   title: "",
      //   number: "",
      //   hamlet_id: "",
      //   purpose: "",
      // },
      citizen: [],
      hamlets: [],
    };
  },

  methods: {
    async getCitizenOption() {
      let response = await module.setTreeSelect("citizen");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.citizen = response.data;
        this.citizen.unshift({
          label: "Pilih Penduduk",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua RW",
          id: "",
          isDisabled: true,
        });
      }
    },
    chooseCitizen(value) {
      this.$bvModal.hide("modal-citizen");
      this.form.father_id = value.id;
      this.form.citizen_id = value.id;
      this.form.citizen_name = value.name;
      this.form.citizen_id_card_number = value.id_card_number;
      this.form.citizen_gender_display = value.gender_display;
      this.form.citizen_birt_place = value.birt_place;
      this.form.citizen_birt_date = value.birt_date;
      this.form.citizen_citizenship = value.citizenship;
      this.form.citizen_profession = value.profession;
      this.form.citizen_address = value.address;
      this.form.phone = value.phone;
      this.form.birt_place_date =
        value.birt_place + ", " + value.birt_date_display;
      this.form.citizen_religion = value.religion;
      this.form.citizen_is_married = value.is_married_display;
      this.form.citizen_is_married_display = value.is_married_display;
    },
  },
  mounted() {
    // this.getCitizenOption();
    console.log("purpose", this.purpose);
    if (this.purpose == "edit") {
      this.form.birt_place_date =
        this.form.citizen_birt_place + ", " + this.form.citizen_birt_date;
      console.log("form", this.form);
    }
    this.getHamletHeadOption();
  },
  watch: {
    form: function (newVal, oldVal) {
      console.log("form wath", this.form);
    },
  },
};
</script>

<style>
</style>