<template>
  <div>
    <b-modal
      :id="id"
      size="xl"
      title="Pilih Penduduk"
      hide-footer
    >
      <Table purpose="modal-citizen"  @chooseCitizen="chooseCitizen"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/citizen/Table.vue";

export default {
  props:{
    id:{
      type: String,
      default: "modal-citizen"
    },
    returnEmit: {
      type: String,
      default: "chooseCitizen"
    }
  },

  components: {
    Table,
  },

  methods: {
    chooseCitizen(value) {
      console.log('return emit', this.returnEmit)
      this.$emit(this.returnEmit, value);
    }, 
  },
  mounted(){
    console.log('moun return emit', this.returnEmit)
  }
};
</script>

<style>
</style>
