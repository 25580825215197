<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Penduduk"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table striped hover class="mt-3" :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }"
        />
      </template>
      <template #cell(created_at)="data">
        {{
          new Date(Date.parse(data.item.created_at)).toLocaleString("id-ID", {
            weekday: "long",
            month: "numeric",
            year: "numeric",
            day: "numeric",
          })
        }}
      </template>
      <template #cell(actions)="data">
        <b-button
          v-if="purpose == 'modal-citizen'"
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          @click="chooseCitizen(data.item)"
          >Pilih</b-button
        >

        <b-form-checkbox
          v-if="purpose == 'modal-citizen-multiple'"
          :checked="data.item.checked"
          :id="`checkbox-pick-item-${data.item.id}`"
          :name="`checkbox-pick-item-${data.item.id}`"
          @change="checkCitizen(data.item, $event)"
        >
        </b-form-checkbox>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage" @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  props: {
    purpose: String,
    familyCollection: {
      type: Array,
      default: [],
    },
    source:{
      type: String,
      default: ""
    }
  },

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "id_card_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "gender_display",
          label: "Kelamin",
          sortable: true,
        },
        {
          key: "birt_date",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
        },
        {
          key: "family_number",
          label: "Nomor KK",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
    };
  },

  methods: {
    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      console.log('source', this.source)
      if(this.source == 'expense'){
        filterParams += `&is_blt=1`
      }
      let response = await module.paginate(
        "citizen",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.setCheckedItem()
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    chooseCitizen(value) {
      console.log("table value", value);
      this.$emit("chooseCitizen", value);
    },

    checkCitizen(data, evt) {
      if (evt) {
        this.$emit("checkCitizen", data);
      } else {
        this.$emit("unCheckCitizen", data);
      }
    },

    setCheckedItem() {
      if (this.familyCollection.length > 0) {
        let found;
        this.items.forEach((value) => {
          found = this.familyCollection.find((x) => x.citizen_id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
      // localStorage.setItem("dataSelectedPackage", JSON.stringify(this.itemProps))
    },

    filterByName() {
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("lab-templates/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "1105") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Template Laporan Lab" }]);
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
